<template>
    <div>
       <user-comp :type="type"></user-comp>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import UserComp from "@/components/admin/user/UserComp";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
    name: "create-user",
    components : {UserComp},

    setup() {

        const type = ref('create');
        onMounted(() => {
            setCurrentPageBreadcrumbs("Create User", ["Users"] , checkPermission('users.store') ? 'create-user': null);
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
